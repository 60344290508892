import PropTypes from 'prop-types';
import React from 'react';

import { getFourthSubmissionData, submitToFourth } from '@API/Integrations/FourthV2API';
import { retryableAPICall } from '@API/common-api-utils';

import Integration from './Integration';

const fourthV2InputConfig = [
  {
    id: 'location',
    label: 'Location',
    type: 'select',
    required: true,
    exclude: true,
    url: 'integration/data/fourth/predefined/location',
    dataMapper: (data) => {
      return { label: data, value: data };
    },
  },
  {
    id: 'division',
    label: 'Division',
    type: 'select',
    required: true,
    exclude: true,
    url: 'integration/data/fourth/predefined/division?location={locationId}',
    dataMapper: (data) => {
      return { label: data, value: data };
    },
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
    type: 'select',
    required: true,
    exclude: true,
    url: 'integration/data/fourth/predefined/jobTitle?division={divisionId}&location={locationId}',
    dataMapper: (data) => {
      return { label: data, value: data };
    },
  },
  {
    id: 'paidByRota',
    label: 'Paid By Rota',
    type: 'select',
    options: [
      { label: 'Yes', value: 'Y' },
      { label: 'No', value: 'N' },
    ],
    required: true,
  },
  {
    id: 'payType',
    label: 'Pay Type',
    type: 'select',
    options: [
      { label: 'Shift', value: 'S' },
      { label: 'Hourly', value: 'H' },
    ],
    required: true,
  },
  {
    id: 'employmentType',
    label: 'Employment Type',
    type: 'select',
    url: 'integration/data/fourth/employmentType',
    required: true,
  },
  {
    id: 'contractHours',
    label: 'Contract Hours',
    type: 'number',
    required: true,
    defaultValue: 0,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'employeeSource',
    label: 'Employee Source',
    type: 'select',
    url: 'integration/data/fourth/employeeSource',
  },
  {
    id: 'inclInRota',
    label: 'Included in Rota',
    type: 'select',
    options: [
      { label: 'Yes', value: 'Y' },
      { label: 'No', value: 'N' },
    ],
    required: true,
  },
  {
    id: 'rateOfPay',
    label: 'Rate of Pay',
    type: 'number',
  },
  {
    id: 'annualSalary',
    label: 'Annual Salary',
    type: 'number',
  },
  {
    id: 'paymentMethod',
    label: 'Payment Method',
    url: 'integration/data/fourth/paymentMethod',
    type: 'select',
    required: true,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
    required: true,
  },
  {
    id: 'contractId',
    label: 'Contract',
    type: 'select',
    required: true,
    exclude: true,
    url: 'integration/data/fourth/predefined/contract/{location}/{jobTitle}',
    dataMapper: (data) => {
      return { label: data.contractName, value: data.contractCode };
    },
  },
];

const resolveUrlAndDisabledState = (urlTemplate, formData, id) => {
  const locationId = formData.location;
  const divisionId = formData.division;
  const jobTitle = formData.jobTitle;

  switch (id) {
    case 'division':
      return locationId
        ? { url: urlTemplate.replace('{locationId}', locationId), isDisabled: false }
        : { url: undefined, isDisabled: true };
    case 'jobTitle':
      return divisionId
        ? {
            url: urlTemplate.replace('{divisionId}', divisionId).replace('{locationId}', locationId),
            isDisabled: false,
          }
        : { url: undefined, isDisabled: true };
    case 'contractId':
      return locationId && jobTitle
        ? {
            url: urlTemplate.replace('{location}', locationId).replace('{jobTitle}', jobTitle),
            isDisabled: false,
          }
        : { url: undefined, isDisabled: true };

    default:
      return { url: urlTemplate, isDisabled: false };
  }
};

function valueSetter(url, value, isDisabled, isComplete, type, formData, id) {
  let convertedValue = type === 'number' && !formData[id] ? 0 : type === 'checkbox' ? formData[id] === 1 : value;
  const { url: convertedUrl, isDisabled: convertedIsDisabled } = resolveUrlAndDisabledState(url, formData, id);
  return { url: convertedUrl, value: convertedValue, isDisabled: convertedIsDisabled || isDisabled };
}

function FourthV2Form({ applicantId }) {
  const formSubmit = async (applicantId, formData) => {
    try {
      const { status, message, ...rest } = await retryableAPICall(() =>
        submitToFourth(
          {
            annualSalary: formData.annualSalary === 0 ? null : formData.annualSalary,
            location: formData.location,
            division: formData.division,
            paidByRota: formData.paidByRota,
            payType: formData.payType,
            inclInRota: formData.inclInRota,
            employmentType: formData.employmentType,
            contractHours: formData.contractHours,
            employeeSource: formData.employeeSource,
            paymentMethod: formData.paymentMethod,
            startDate: formData.startDate,
            terminationDate: formData.terminationDate,
            jobTitle: formData.jobTitle,
            rateOfPay: formData.rateOfPay === 0 ? null : formData.rateOfPay,
            contractId: formData.contractId,
          },
          applicantId,
        ),
      );

      return status === 'NOT_FOUND_ERROR' ? 'NOT_FOUND_ERROR' : { status, message, data: rest };
    } catch (error) {
      console.error('Error during form submission:', error);
      return { status: 'ERROR', message: 'An unexpected error occurred during data fetching.' };
    }
  };

  const handleChange = (id, value, formData, setFormData) => {
    let updatedData = { ...formData, [id]: value };

    if (id === 'location' || id === 'division') {
      updatedData.division = id === 'location' ? null : updatedData.division;
      updatedData.jobTitle = null;
    }

    if (id === 'location' || id === 'jobTitle') {
      updatedData.contractId = null;
    }

    if (id === 'paidByRota') {
      if (value === 'N') {
        updatedData = {
          ...updatedData,
          rateOfPay: 0,
          payType: null,
          annualSalary: updatedData.annualSalary || 0,
          employmentType: 'FullTime',
        };
      } else if (value === 'Y') {
        updatedData = {
          ...updatedData,
          annualSalary: 0,
          rateOfPay: updatedData.rateOfPay || 0,
          payType: updatedData.payType || '',
          employmentType: 'Flex',
        };
      }
    }

    if (id === 'payType') {
      if (value === 'H') {
        updatedData = {
          ...updatedData,
          inclInRota: 'Y',
        };
      }
    }

    setFormData(updatedData);
  };

  async function getFourthV2Data(applicantId) {
    try {
      const resp = await retryableAPICall(() => getFourthSubmissionData(applicantId));

      if (resp === 'NOT_FOUND_ERROR') {
        return 'NOT_FOUND_ERROR';
      }

      const { status, message, supplementaryDataFields } = resp;

      if (status !== 'SUCCESS') {
        if (status == 'PENDING') {
          return {
            status: 'PENDING',
            message: message,
            data: supplementaryDataFields.dataFields,
          };
        }

        return {
          status: 'FAILED',
          message: message,
          error: message,
          data: supplementaryDataFields.dataFields,
        };
      }

      return {
        status: 'SUCCESS',
        data: supplementaryDataFields.dataFields,
      };
    } catch (error) {
      console.error('Error fetching data:', error);

      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  const validationConfig = () => {
    return fourthV2InputConfig.reduce((acc, { id, type, required, inputProps }) => {
      const { min, max } = inputProps || {};
      return [...acc, { id, required, ...(type === 'number' ? { type, min, max } : {}) }];
    }, []);
  };

  return (
    <Integration
      title="Fourth"
      applicantId={applicantId}
      integrationName="Fourth"
      formConfig={fourthV2InputConfig}
      formSubmit={formSubmit}
      valueSetter={valueSetter}
      getSubmission={getFourthV2Data}
      componentName="FourthV2Form"
      customOnChange={handleChange}
      getValidationConfig={validationConfig}
    />
  );
}

FourthV2Form.propTypes = {
  applicantId: PropTypes.string,
};

FourthV2Form.defaultProps = {
  applicantId: null,
};

export default FourthV2Form;
